// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  mapboxAPIToken: 'pk.eyJ1IjoiZHJlYW0tZW5lcmd5IiwiYSI6ImNsMDZlNzdtbDA1dTUzZG5yc3g2cWpkNGgifQ.VZapnLxVubK-Bwok0SsA9Q',
  stripeApiKey: 'pk_test_51LKGQvAsBpSApI43M3wsSsWAnCWJkOwf6rGBJnkG64AXtsRwIBf8y07aysCyJ4vBbfnc8qOF9cH6fj0FehlqCwOo00gsQtX8YH',
  baseUrl: 'https://mobility-main.dev.dream-energy-mobility.fr',
  googleRecaptchaKey: '6Ld6hUgqAAAAANYtanfrhV-Y4hJIw0rqdja3q_ZP'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
