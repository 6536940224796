<div class="root-charge-prepare">
  <div class="circle-xmark" [routerLink]="['..']" routerLinkActive="router-link-active">
    <img src="./assets/ic_circle_xmark.svg" />
  </div>
  <div id="information">
    <h2 i18n>Connector</h2>
    <hr />
    <div id="connector-id">ID: {{connector?.key?.toUpperCase()}}</div>
    <div id="connector-power">Power: {{connector!.maxPower / 1000}} kW</div>
  </div>
  <div id="payment">
    <h2 i18n>Method of payment</h2>
    <hr />
    <div id="credit-card" (click)="onClickCreditCard()">
      <img id="credit-card-icon" src="./assets/ic_creditCard.svg">
      <span id="credit-card-text">Pay with credit card</span>
      <img id="credit-card-chevron-right" src="./assets/chevron-right.svg">
    </div>
  </div>
</div>
<div id="payment-overlay" *ngIf="cardModalDisplayed">
  <form id="payment-container">
    <div class="circle-xmark" (click)="closeCardModal()">
      <img src="./assets/ic_circle_xmark.svg" />
    </div>
    <h3 id="enter-payment-information-title" i18n>Enter your payment information</h3>
    <div id="payment-element">
      <!-- a Stripe Element will be inserted here. -->
    </div>
    <!-- the 'false' in the click method is to prevent the submit from reloading the page -->
    <button id="authorize-button" (click)="onAuthorizePayment(); false"
      [disabled]="!authorizeButtonActivated">Authorize {{paymentAmountInEuros?.toFixed(2)?.replace('.', ',')}}€</button>
  </form>
</div>
