import { environment } from 'src/environments/environment';
import { Ereceipt } from './api';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class EreceiptService {

  constructor() { }

  public find(locationKey: string | undefined, evseKey: string | undefined, fromDate: Date, toDate: Date, cardLast4Digits: string): Promise<Ereceipt[]> {
    const params: URLSearchParams = new URLSearchParams();
    if (!!locationKey) {
      params.append('locationKey', locationKey.toString());
    }
    if (!!evseKey) {
      params.append('evseKey', evseKey.toString());
    }
    params.append('fromDateTime', fromDate.toISOString());
    params.append('toDateTime', toDate.toISOString());
    params.append('cardLast4Digits', cardLast4Digits);
    return fetch(environment.baseUrl +'/api/e-receipts/find?' + params, {
      headers: {
        Accept: 'application/json'
      }
    })
      .then(res => res.json())
      .then(res => {
        return res as Ereceipt[]
      })

  }

  public getEreceiptImageDataURL(locationKey: string | undefined, evseKey: string | undefined, fromDate: Date, toDate: Date, cardLast4Digits: string): Promise<string> {
    const params: URLSearchParams = new URLSearchParams();
    if (!!locationKey) {
      params.append('locationKey', locationKey.toString());
    }
    if (!!evseKey) {
      params.append('evseKey', evseKey.toString());
    }
    params.append('fromDateTime', fromDate.toISOString());
    params.append('toDateTime', toDate.toISOString());
    params.append('cardLast4Digits', cardLast4Digits);
    return fetch(environment.baseUrl + '/api/e-receipts/find?' + params, {
      headers: {
        Accept: 'image/png'
      }
    })
      .then(res => res.arrayBuffer())
      .then(res => "data:image/png;base64," + this.arrayBufferToBase64(res))
  }

  private arrayBufferToBase64( buffer: ArrayBuffer ): string {
    let binary = '';
    const bytes = new Uint8Array( buffer );
    const len = bytes.byteLength;
    for (let i = 0; i < len; i++) {
        binary += String.fromCharCode( bytes[ i ] );
    }
    return window.btoa( binary );
  }
}



