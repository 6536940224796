import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { BrowserModule } from '@angular/platform-browser';
import { ChargeModule } from './charge/charge.module';
import { EReceiptModule } from './e-receipt/e-receipt.module';
import { MainMapModule } from './main-map/main-map.module';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { TickettModule } from './ticket/ticket.module';
import { RecaptchaService } from './services/recaptcha.service'; // Add this line

// Helper function to load the recaptcha script on app startup
export function loadRecaptcha(recaptchaService: RecaptchaService) {
  return () => recaptchaService.loadRecaptchaScript();
}

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    MainMapModule,
    ChargeModule,
    AppRoutingModule,
    EReceiptModule,
    TickettModule
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: loadRecaptcha,
      deps: [RecaptchaService],
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
