import { Appearance, StripeElements } from '@stripe/stripe-js';
import { Component, OnInit } from '@angular/core';
import { PaymentsService, StripePaymentView } from 'src/app/services/payments.service';

import { ActivatedRoute } from '@angular/router';
import { Connector } from 'src/app/services/api';
import { ConnectorsService } from 'src/app/services/connectors.service';
import { stripe } from 'src/main';

@Component({
  selector: 'app-charge-prepare',
  templateUrl: './charge-prepare.component.html',
  styleUrls: ['./charge-prepare.component.scss']
})
export class ChargePrepareComponent implements OnInit {

  /**
   * Connector initialized from its id at the initialization of the Component
   */
  public connector: Connector | undefined;

  /**
   * Whether the card model is displayed or not. Activated by the user.
   */
  public cardModalDisplayed: boolean = false;

  /**
   * Whether the Authorize button is activated. When user enters valid informations.
   */
  public authorizeButtonActivated: boolean = false;

  /**
   * Amount of the payment.
   */
  public paymentAmountInEuros: number | undefined;

  /**
   * Stripe elements.
   */
  public elements: StripeElements | undefined;

  constructor(private route: ActivatedRoute,
    private connectorService: ConnectorsService,
    private paymentService: PaymentsService) { }

  ngOnInit(): void {
    // Retrieve connector id from URL
    this.route.url.subscribe(urlSegments => {
      const connectorId: string = urlSegments.at(-1)!.path;
      this.connectorService.getConnector(connectorId.toLowerCase()).then(connector => {
        if (connector == null) {
          // TODO Error + redirection
        } else {
          this.connector = connector
        }
      });
    })
  }

  public closeCardModal(): void {
    this.cardModalDisplayed = false;
  }

  public async onClickCreditCard(): Promise<void> {
    const stripePaymentView: StripePaymentView = await this.paymentService.createPreauthorizationPayment();
    this.paymentAmountInEuros = stripePaymentView.amount / 100;
    this.cardModalDisplayed = true;
    const options = {
      clientSecret: stripePaymentView.clientSecret,
      appearance: {
        theme: 'flat' as "stripe" | "night" | "flat" | "none" | undefined
      }
    };
    this.elements = (await stripe)!.elements(options);
    const paymentElement = this.elements.create("payment");
    paymentElement.on('change', (event) => {
      // If payment element is complete (all information entered by user), activate the button
      this.authorizeButtonActivated = event.complete;
    })
    // Need to delay display waiting for the payment-element div to be displayed in the DOM
    setTimeout(() => {
      paymentElement.mount("#payment-element");
    }, 200);
  }

  public async onAuthorizePayment(): Promise<void> {
    // Check documentation here https://stripe.com/docs/js/payment_intents/confirm_payment
    (await stripe)?.confirmPayment({
      elements: this.elements!,
      confirmParams: {
        // Return URL where the customer should be redirected after the PaymentIntent is confirmed.
        // TODO To be implemented
        return_url: 'https://example.com',
      },
    })
      .then(function (result) {
        if (result.error) {
          // TODO Inform the customer that there was an error.
          console.log(result.error);
        }
      });
  }

}
