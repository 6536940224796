import {  Injectable } from '@angular/core';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class RecaptchaService {

  private isLoaded = false;

  constructor() {}

  loadRecaptchaScript() {
    if (this.isLoaded) {
      return;
    }

    const script = document.createElement('script');
    script.src = `https://www.google.com/recaptcha/api.js?render=${environment.googleRecaptchaKey}`;
    script.async = true;
    script.defer = true;
    document.body.appendChild(script);

    this.isLoaded = true;  // Ensures the script is only loaded once
  }
}
