import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

export interface StripePaymentView {
  key: string
  clientSecret: string
  amount: number
}

@Injectable({
  providedIn: 'root'
})
export class PaymentsService {

  constructor() { }

  public createPayment(): Promise<StripePaymentView> {
    return fetch(environment.baseUrl + `/api/payments`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({amount: 5000, currency: 'EUR_CENTS'})
    })
      .then(res => {
        if (!res.ok) {
          throw new Error("Not 2xx response");
        } else {
          return res.json().then(j => j as StripePaymentView);
        }
      })
  }

  public createPreauthorizationPayment(): Promise<StripePaymentView> {
    return fetch(environment.baseUrl + `/api/payments/authorization`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({amount: 5000, currency: 'EUR_CENTS'})
    })
      .then(res => {
        if (!res.ok) {
          throw new Error("Not 2xx response");
        } else {
          return res.json().then(j => j as StripePaymentView);
        }
      })
  }
}
