<div id="ticket" *ngIf="!errorMessage">
  <img id="dream-energy-logo" src="./assets/dream_energy.svg" />
  <div id="ticket-find">
    <h2 class="ticket-title" i18n>Get your ticket</h2>
    <div class="subsection">
      <span class="subtitle" i18n>From the charging station :</span>
      <div id="location">
        <div>{{location?.name}}</div>
        <div>{{location?.address}} - {{location?.zipCode}} {{location?.city}}</div>
      </div>
    </div>
    <div id="date-subsection" class="subsection">
      <span class="subtitle" i18n>Charging session date :</span>
      <div id="date">{{date | date: 'dd MMMM yyyy'}}</div>
    </div>
    <div id="button-wrapper" class="subsection">
      <button class="button" (click)="onFindButtonClick()" i18n>Find my ticket</button>
    </div>
    <div id="select-ticket" *ngIf="paymentTerminalChargingSessions.length > 0">
      <div *ngFor="let paymentTerminalChargingSession of paymentTerminalChargingSessions" class="ticket-item subsection">
        <span class="ticket-item-date">{{paymentTerminalChargingSession.creationDate| date:'shortTime'}}</span>
        <span class="ticket-item-energy">{{fromWhToKwhFloor(paymentTerminalChargingSession.finalEnergyDeliveredInWh)}} kWh</span>
        <span class="ticket-item-amount">{{ paymentTerminalChargingSession.payment.amount / 100}} €</span>
        <button class="ticket-item-download" (click)="onClickTicket(paymentTerminalChargingSession)" i18n>Download</button>
      </div>
    </div>
  </div>  
</div>
<div id="error" *ngIf="!!errorMessage">
  <!-- <div class="circle-left" (click)="resetErrorMessage()">
    <img src="./assets/circle-chevron-left.svg" />
  </div> -->
  <div id="error-container">
    <img id="face-confused" src="./assets/face-confused.svg" />
    <div id="error-message">{{errorMessage}}</div>
  </div>
</div>
