import { Injectable } from '@angular/core';

export interface UserLocation {
  lat: number
  lng: number
}

@Injectable({
  providedIn: 'root'
})
export class UserLocationService {

  constructor() { }

  public getUserLocation(): Promise<UserLocation> {
    if (navigator.geolocation) {
      return new Promise((resolve, reject) => {
        navigator.geolocation.getCurrentPosition((position) => {
          if (position) {
            resolve( {
              lat: position.coords.latitude,
              lng: position.coords.longitude
            });
          }
        },
          (error) => reject(error))
      });
    } else {
      return new Promise((resolve, reject) => {});
    }
  }
}
