<!-- QR code mode-->
<ng-container *ngIf="qrcodeMode">
  <div class="root-charge-select-connector qr-code">
    <div class="circle-xmark qr-code" [routerLink]="['']" routerLinkActive="router-link-active">
      <img src="./assets/ic_circle_xmark.svg" />
    </div>
    <div class="middle">
      <h2 class="qr-code" i18n>Scan and charge</h2>
      <h3 class="qr-code" i18n>Scan the connector's QR code you'd like to use</h3>
      <div class="qr-code-scanner">
        <img id="qr-code-check" *ngIf="qrCodeCheck" src="./assets/check.svg" />
        <zxing-scanner [autostart]="true" [(device)]="currentDevice" (camerasFound)="onCamerasFound($event)"
          (scanSuccess)="onScanSuccess($event)"></zxing-scanner>
      </div>
    </div>
    <div id="toggle-mode-button-qr-code" class="toggle-mode-button qr-code" (click)="toggleMode()">
      <span class="toggle-mode-text qr-code" i18n>Enter connector ID</span>
    </div>
  </div>
</ng-container>
<ng-container *ngIf="!qrcodeMode">
  <div class="root-charge-select-connector connector-id">
    <div class="circle-xmark" [routerLink]="['']" routerLinkActive="router-link-active">
      <img src="./assets/ic_circle_xmark.svg" />
    </div>
    <div class="middle">
      <h2 class="connector-id" i18n>Scan and charge</h2>
      <h3 class="connector-id" i18n>Enter the ID of the connector you'd like to use</h3>
      <div id="connector-id-input-container">
        <input id="connector-id-input" placeholder="Ex: ABCD1" type="text" (keyup.enter)="onEnter()"
          [formControl]="connectorId" autocomplete="off" (focus)="onConnectorInputFocus()"
          (blur)="onConnectorInputBlur()" />
        <div id="connector-options">
          <div *ngFor="let connectorOption of connectorOptions | async" class="connector-option"
            (click)="onConnectorSelected(connectorOption.key)">
            <img [src]="getConnectorIconSrc(connectorOption)" class="connector-icon" />
            <span class="connector-option">{{connectorOption.key.toUpperCase()}}</span>
            <span class="connector-option">{{connectorOption.maxPower / 1000}} kW</span>
            <span *ngIf="connectorOption.connectorStatus === 'AVAILABLE'"
              class="connector-option available">available</span>
            <span *ngIf="connectorOption.connectorStatus !== 'AVAILABLE'"
              class="connector-option unavailable">unavailable</span>
          </div>
        </div>
      </div>
    </div>
    <div class="helper">
      <h3 class="connector-id" i18n>The ID and the QR code of the connector is located on the connector itself</h3>
    </div>
    <div id="toggle-mode-button-connector-id" class="toggle-mode-button connector-id" (click)="toggleMode()">
      <span class="toggle-mode-text connector-id" i18n>Scan QR code</span>
    </div>
  </div>
</ng-container>
