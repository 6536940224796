import { Injectable } from '@angular/core';
import { Location } from "./api";
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LocationsService {

  constructor() { }

  public getLocations(): Promise<Location[]> {
    return fetch(environment.baseUrl + '/api/locations')
      .then(res => res.json())
      .then(res => {
        return res as Location[]
      })
  }

  public getLocationByConnectorKey(connectorKey: string): Promise<Location> {
    return fetch(environment.baseUrl + '/api/connectors/' + connectorKey + "/location")
      .then(res => {
          // Check if the response is not OK (e.g., 404 or other error status)
        if (!res.ok) {
          throw new Error(`Error: ${res.status} - ${res.statusText}`);
        }
        return res.json()
      })
      .then(res => {
        return res as Location
      })
      .catch(error => {
        console.log(error);
        throw error;
      })
  }

  public getLocation(key: string): Promise<Location> {
    return fetch(environment.baseUrl + '/api/locations/' + key)
      .then(res => res.json())
      .then(res => {
        return res as Location
      })
  }

  public getLocationProjects(): Promise<Location[]> {
    return fetch(environment.baseUrl + '/api/locations/projects')
      .then(res => res.json())
      .then(res => {
        return res as Location[]
      })
  }

}
