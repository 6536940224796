import * as L from "leaflet";

import { AfterViewInit, Component } from '@angular/core';
import { Connector, Location } from "../../services/api";
import { UserLocation, UserLocationService } from "../../services/user-location.service";

import { LocationsService } from "../../services/locations.service";
import { environment } from "src/environments/environment";

@Component({
  selector: 'app-mobility-map',
  providers: [LocationsService, UserLocationService],
  templateUrl: './mobility-map.component.html',
  styleUrls: ['./mobility-map.component.scss']
})
export class MobilityMapComponent implements AfterViewInit {

  private readonly initialPosition = L.latLng([46.6217467, 1.8317521]);
  private readonly initialZoomLevel = 6;

  // configuration of initial map
  osmDe = {
    url: "https://api.mapbox.com/styles/v1/mapbox/light-v10/tiles/{z}/{x}/{y}?access_token=" + environment.mapboxAPIToken,
    options: {
      detectRetina: true,
      tileSize: 512,
      zoomOffset: -1,
      maxZoom: 18
    }
  };

  private activeStationIcon = L.icon({
    iconUrl: 'assets/ic_Active_Station.png',
    iconSize: [57, 65], // size of the icon
    iconAnchor: [28, 57], // point of the icon which will correspond to marker's location
  });

  private notActiveStationIcon = L.icon({
    iconUrl: 'assets/ic_NotActive_Station.png',
    iconSize: [57, 65], // size of the icon
    iconAnchor: [28, 57], // point of the icon which will correspond to marker's location
  });

  private outOfServiceStationIcon = L.icon({
    iconUrl: 'assets/ic_Outofservice_Station.png',
    iconSize: [57, 65], // size of the icon
    iconAnchor: [28, 57], // point of the icon which will correspond to marker's location
  });

  private userLocationIcon = L.icon({
    iconUrl: 'assets/ic_mylocation.png',
    iconSize: [26, 26], // size of the icon
    iconAnchor: [13, 11], // point of the icon which will correspond to marker's location
  });

  private mapOptions = {
    layers: [L.tileLayer(this.osmDe.url, this.osmDe.options)],
    center: this.initialPosition,
    zoomControl: false, // Remove + / - buttons from the map
    zoomSnap: 0.25,
    zoom: this.initialZoomLevel,
    fullscreenControl: true,
    fullscreenControlOptions: {
      position: "topleft",
      title: "Vollbild-Anzeige",
      titleCancel: "Vollbild-Anzeige verlassen",
      forcePseudoFullscreen: true // limit fullscreen to window of map
    }
  } as L.MapOptions;

  private map: L.Map | undefined;

  ngAfterViewInit(): void {
    this.initMap();
  }

  constructor(private locationService: LocationsService, private userLocationService: UserLocationService) {

  }

  private initMap(): void {
    this.map = L.map("mobility-map", this.mapOptions);

    this.map.on("enterFullscreen", () =>
      this.map!.invalidateSize()
    );
    this.map.on("exitFullscreen", () =>
      this.map!.invalidateSize()
    );
    // Add the open station to the map
    this.locationService.getLocations().then((locations: Location[]) => locations.forEach(location => {
      if (!(location.connectors.every((c: Connector) => c.connectorStatus != 'AVAILABLE'))) {
        L.marker([location.latitude, location.longitude], { icon: this.activeStationIcon, zIndexOffset: 20 }).addTo(this.map!);
      } else {
        L.marker([location.latitude, location.longitude], { icon: this.outOfServiceStationIcon, zIndexOffset: 10 }).addTo(this.map!);
      }
    }));
    // Add the station under construction to the map
    this.locationService.getLocationProjects().then((locations: Location[]) => locations.forEach(location => {
      L.marker([location.latitude, location.longitude], { icon: this.notActiveStationIcon, zIndexOffset: 0 }).addTo(this.map!);
    }));
    // Add the user position to the map
    this.userLocationService.getUserLocation().then((userLocation: UserLocation) => L.marker([userLocation.lat, userLocation.lng], { icon: this.userLocationIcon, zIndexOffset: 30 }).addTo(this.map!))
  }
}
