import { CommonModule } from '@angular/common';
import { EReceiptFindComponent } from './e-receipt-find/e-receipt-find.component';
import { NgModule } from '@angular/core';
import { NgOtpInputModule } from 'ng-otp-input';

@NgModule({
  declarations: [
    EReceiptFindComponent
  ],
  imports: [
    CommonModule,
    NgOtpInputModule
  ],
  exports: [EReceiptFindComponent]
})
export class EReceiptModule { }
