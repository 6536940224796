import { ElementRef, Injectable } from '@angular/core';

import { jsPDF } from "jspdf";

@Injectable({
  providedIn: 'root'
})
export class PdfService {

  constructor() { }

  public exportAsPdf(image: HTMLImageElement, filename: string): void {
    let canvas = document.createElement("canvas");
    canvas.width = image.width;
    canvas.height = image.height;
    let ctx = canvas.getContext("2d")!;
    // This will draw image
    ctx.drawImage(image, 0, 0);
    // Convert the drawn image to Data URL
    let dataURL = canvas.toDataURL("image/png");
    const doc = new jsPDF('p', 'pt', [ image.width, image.height]);
    doc.addImage(dataURL, 'png', 0, 0, image.width, image.height);
    doc.save(filename);
  }
}
