<div id="e-receipt">
  <img id="dream-energy-logo" src="./assets/dream_energy.svg" />
  <div id="e-receipt-find" *ngIf="toggleFindDisplay ==='find'">
    <h2 class="ereceipt-title" i18n>Get your electronic payment receipt</h2>
    <div class="subsection">
      <span class="subtitle" i18n>From the charging station :</span>
      <div id="location">
        <div>{{location?.name}}</div>
        <div>{{location?.address}} - {{location?.zipCode}} {{location?.city}}</div>
      </div>
    </div>
    <div id="date-subsection" class="subsection">
      <span class="subtitle" i18n>Charging session date :</span>
      <div id="date">{{date | date: 'dd MMMM yyyy'}}</div>
    </div>
    <div class="subsection">
      <span id="enter-digits" i18n>Enter last 4 digits of your payment card :</span>
      <div id="otp">
        <img id="card-last-digits" src="./assets/card_last_digits.svg" />
        <ng-otp-input [config]="otpConfig" (onInputChange)="onOtpChange($event)"></ng-otp-input>
      </div>
    </div>
    <div id="button-wrapper">
      <button class="button" [disabled]="lastCardDigits.length != 4" (click)="onFindButtonClick()" i18n>Find my
        e-receipt</button>
    </div>
    <div id="select-ereceipt" *ngIf="ereceipts.length > 0">
      <div *ngFor="let ereceipt of ereceipts" class="ereceipt-item">
        <span class="ereceipt-item-date">{{ereceipt.localDateTime | date:'shortTime'}}</span>
        <span class="ereceipt-item-amount">{{ereceipt.amount / 100 | currency:'EUR'}}</span>
        <button class="ereceipt-item-see" (click)="onClickEreceipt(ereceipt)">See</button>
      </div>
    </div>
  </div>
  <div id="e-receipt-display" *ngIf="toggleFindDisplay ==='display'">
    <div style="margin-top: 10px;" (click)="backFromEreceiptDisplay()">
      <img src="./assets/circle-chevron-left.svg" />
    </div>
    <h2 class="ereceipt-title" style="margin-top: 10px;" i18n>Your e-receipt</h2>
    <div id="ereceipt-display-container">
      <img #ereceiptImage id="ereceipt-image" [src]="ereceiptImgSrc" />
      <div>
        <button class="button" (click)="onPdfButtonClick()" i18n>Download as pdf</button>
      </div>
    </div>
  </div>
</div>
<div id="error" *ngIf="!!errorMessage">
  <div class="circle-left" (click)="resetErrorMessage()">
    <img src="./assets/circle-chevron-left.svg" />
  </div>
  <div id="error-container">
    <img id="face-confused" src="./assets/face-confused.svg" />
    <div id="error-message">{{errorMessage}}</div>
  </div>
</div>
