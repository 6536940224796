import { ChargePrepareComponent } from './charge-prepare/charge-prepare.component';
import { ChargeSelectConnectorComponent } from './charge-select-connector/charge-select-connector.component';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { ZXingScannerModule } from '@zxing/ngx-scanner';

@NgModule({
  declarations: [
    ChargeSelectConnectorComponent,
    ChargePrepareComponent
  ],
  imports: [
    CommonModule,
    ZXingScannerModule,
    RouterModule,
    ReactiveFormsModule
  ],
  exports: [
    ChargeSelectConnectorComponent
  ]
})
export class ChargeModule {

}


