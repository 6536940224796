import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { Observable, OperatorFunction, debounceTime, distinctUntilChanged, map, mergeMap, startWith } from 'rxjs';
import { UserLocation, UserLocationService } from 'src/app/services/user-location.service';

import { Connector } from 'src/app/services/api';
import { ConnectorsService } from 'src/app/services/connectors.service';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-charge-select-connector',
  templateUrl: './charge-select-connector.component.html',
  styleUrls: ['./charge-select-connector.component.scss'],
})
export class ChargeSelectConnectorComponent implements OnInit {


  currentDevice: MediaDeviceInfo | undefined;

  /**
   * Whether the component is displayed in QR-code mode, or in manual enter id
   */
  qrcodeMode: boolean = true;

  /**
   * Whether the green check on top of the camera box is displayed or not
   */
  qrCodeCheck: boolean = false;

  /**
   * Linked to the input where the user enter the connector id
   */
  connectorId: FormControl = new FormControl('');

  /**
   * Connector options displayed as suggestions to the user below the input
   */
  connectorOptions: Observable<Connector[]> | undefined;

  /**
   * Stores the user position. Initialized at component initialization.
   */
  lat: number | undefined;
  lng: number | undefined;

  constructor(private router: Router,
    private route: ActivatedRoute,
    private userLocationService: UserLocationService,
    private connectorService: ConnectorsService) { }

  ngOnInit(): void {
    // Initialize user coordinates to be able to search connectors by distance
    this.userLocationService.getUserLocation().then((userLocation: UserLocation) => {
      this.lat = userLocation.lat;
      this.lng = userLocation.lng;
    })
  }

  /**
   * Entrypoint when a QR code has been scanned
   *
   * @param result THe content of the Qr code which has been scanned
   */
  public onScanSuccess(result: string) {
    this.connectorService.getConnectorByQRCode(result).catch(e => {
      // TODO Handle error message displayed to user
      console.log('Connection error', e);
    }).then(connector => {
      if (connector != null) {
        // Display green check
        this.qrCodeCheck = true;
        // Wait 1 second then navigate to next screen
        setTimeout(() => {
          this.navigateToNextStep(connector.key);
        }, 1000);
      } else {
        // TODO Fix this
        alert("This connector doesn't exist.");
      }
    });
  }

  public onCamerasFound(devices: MediaDeviceInfo[]) {
    for (const device of devices) {
      if (/back|rear|environment/gi.test(device.label)) {
        this.currentDevice = device;
        break;
      }
    }
  }

  /**
   * Toggles mode between QR code and manual entry of the connector ID.
   */
  public toggleMode(): void {
    this.qrcodeMode = !this.qrcodeMode;
  }

  /**
   * Entrypoint when enter is hit on connector id input
   */
  public onEnter(): void {
    // Convert to lowercase as all connectors keys are lowercase in the back-end
    this.onConnectorSelected(this.connectorId.value.toLowerCase());
  }


  public onConnectorSelected(connectorId: string): void {
    this.connectorService.getConnector(connectorId).catch(e => {
      // TODO Handle error message displayed to user
      console.log('Connection error', e);
    }).then(connector => {
      if (connector != null) {
        this.navigateToNextStep(connector.key);
      } else {
        // TODO Fix this
        alert("This connector doesn't exist.");
      }
    });
  }

  public navigateToNextStep(connectorId: string): void {
    this.router.navigate([connectorId], { relativeTo: this.route });
  }

  public connectorTypeaheadInputFormatter(result: Connector): string {
    return result.key.toUpperCase();
  }

  public getConnectorIconSrc(connector: Connector) {
    if (connector.connectorType === 'IEC_62196_T2_COMBO') {
      return 'assets/ic_ComboType2.png';
    } else if (connector.connectorType === 'CHADEMO') {
      return 'assets/ic_Chademo.png';
    } else if (connector.connectorType === 'IEC_62196_T2') {
      return 'assets/ic_Type2.png';
    } else {
      return '';
    }
  }

  /**
   * Activates typeahead when user clicks on inpujt
   */
  public onConnectorInputFocus(): void {
    this.connectorOptions = this.connectorId.valueChanges.pipe(
      startWith([]),
      debounceTime(200),
      distinctUntilChanged(),
      mergeMap((term) =>
        this.connectorService.searchConnector(term, this.lat!, this.lng!, 5)
      )
    );
  }

  public onConnectorInputBlur(): void {
    // We need to give some delay to the blur otherwise there is a race condition with the click on the connector options
    setTimeout(() => {
      this.connectorOptions = this.connectorId.valueChanges.pipe(
        startWith([])
      );
    }, 200);
  }

}

