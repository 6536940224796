import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Ereceipt, Location } from "../../services/api";

import { ActivatedRoute } from '@angular/router';
import { EreceiptService } from 'src/app/services/ereceipt.service';
import { LocationsService } from 'src/app/services/locations.service';
import { PdfService } from 'src/app/services/pdf.service';

@Component({
  selector: 'app-e-receipt-find',
  templateUrl: './e-receipt-find.component.html',
  styleUrls: ['./e-receipt-find.component.scss']
})
export class EReceiptFindComponent implements OnInit {

  public otpConfig = {
    length: 4,
    allowNumbersOnly: true,
    inputStyles: {
      'border-radius': '18px',
      'width': '48px',
      'height': '64px',
      'background-color': '#f2f8f8',
      'border': '0px'
    }
  };

  /**
   * Stores if the view displays the find form, of the e-receipt view
   */
  public toggleFindDisplay: "find" | "display" = "find";

  /**
   * The Location (charging station) when to search the e-receipt
   */
  public location?: Location;

  /**
   * The date when to store the e-receipt
   */
  public date?: Date;

  /**
   * The last Payment Card digits entered by the user
   */
  public lastCardDigits: string = "";

  public ereceipts: Ereceipt[] = [];

  /**
   * The URL of the e-receipt image, if found
   */
  public ereceiptImgSrc?: string;

  /**
   * The error message to display, if any
   */
  public errorMessage?: string;

  /**
   * We keep a reference to the e-receipt image in order to be able to generate the pdf
   */
  @ViewChild("ereceiptImage") ereceiptImage?: ElementRef;

  constructor(private route: ActivatedRoute, public locationService: LocationsService, public ereceiptService: EreceiptService, public pdfService: PdfService) { }

  ngOnInit(): void {
    // Retrieve location key from the UrL
    this.route.queryParams
      .subscribe(params => {
        this.date = params['date'] == null ? new Date() : new Date(params['date']);
        this.locationService.getLocation(params['locationKey']).then(location => {
          if (location == null) {
            // TODO Error + redirection
          } else {
            this.location = location
          }
        });
      })
  }

  public onOtpChange(value: string): void {
    this.lastCardDigits = value;
  }

  public onFindButtonClick(): void {
    // Make sure date is defined
    if (this.date == undefined) {
      return;
    }

    // Compute fromDate from Date provided as input, and set time to 00:00:00
    const fromDate = new Date(this.date);
    fromDate.setHours(0, 0, 0, 0)

    // Compute toDate from fromDate and add one day
    const toDate = new Date(fromDate);
    toDate.setDate(toDate.getDate() + 1);
    toDate.setHours(0, 0, 0, 0);

    this.ereceiptService.find(this.location?.key, undefined, fromDate, toDate, this.lastCardDigits).then(ereceipts => {
      this.ereceipts = ereceipts;
      // No e-receipt found
      if (ereceipts.length == 0) {
        // Error message will be localized
        this.displayErrorMessage($localize`We are sorry, we cannot find any electronic receipt linked to this payment card. Please contact our customer support.`);
      } else if (ereceipts.length == 1) {
        // Fetch the image from the service
        this.ereceiptService.getEreceiptImageDataURL(this.location?.key, undefined, fromDate, toDate, this.lastCardDigits).then(ereceiptImageBase64 => {
          this.ereceiptImgSrc = ereceiptImageBase64;
        });
        // Toggle the view
        this.toggleFindDisplay = "display";
      }
    });
    // Otherwise a list of available tickets will be displayed
  }

  /**
   * When Download pdf button is clicked
   */
  public onPdfButtonClick(): void {
    this.pdfService.exportAsPdf(this.ereceiptImage?.nativeElement, "ereceipt.pdf");
  }

  private displayErrorMessage(message: string) {
    this.errorMessage = message;
  }

  public resetErrorMessage(): void {
    this.errorMessage = undefined;
  }

  public backFromEreceiptDisplay(): void {
    this.toggleFindDisplay = "find";
    this.ereceiptImgSrc = undefined;
    this.lastCardDigits = "";
    this.ereceipts = [];
  }

  /**
   * When multiple e-receipt are available, this method is called when one of the receipts is clicked.
   *
   * @param ereceipt
   */
  public onClickEreceipt(ereceipt: Ereceipt): void {
    // Compute a timespan of +/- 10 seconds around the date provided by the payment terminal, to make sure we find it (1s is too short)
    let justBeforeDate = new Date(ereceipt.localDateTime);
    justBeforeDate.setSeconds(justBeforeDate.getSeconds() - 10);

    let justAfterDate = new Date(ereceipt.localDateTime);
    justAfterDate.setSeconds(justAfterDate.getSeconds() + 10);

    // Fetch the image from the service
    this.ereceiptService.getEreceiptImageDataURL(this.location?.key, undefined, justBeforeDate, justAfterDate, this.lastCardDigits).then(ereceiptImageBase64 => {
      this.ereceiptImgSrc = ereceiptImageBase64;
    });

    // Toggle the view
    this.toggleFindDisplay = "display";
  }

}
