import { CommonModule } from '@angular/common';
import { TicketFindComponent } from './ticket-find/ticket-find.component';
import { NgModule } from '@angular/core';
import { NgOtpInputModule } from 'ng-otp-input';

@NgModule({
  declarations: [
    TicketFindComponent
  ],
  imports: [
    CommonModule,
    NgOtpInputModule
  ],
  exports: [TicketFindComponent]
})
export class TickettModule { }
