import { CommonModule } from '@angular/common';
import { MainMapComponent } from './main-map.component';
import { MobilityMapComponent } from './mobility-map/mobility-map.component';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ScanButtonComponent } from './scan-button/scan-button.component';

@NgModule({
  declarations: [
    MainMapComponent,
    MobilityMapComponent,
    ScanButtonComponent
  ],
  imports: [
    CommonModule,
    RouterModule
  ],
  exports: [
    MainMapComponent
  ]
})
export class MainMapModule { }
