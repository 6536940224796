import { environment } from 'src/environments/environment';
import { Connector } from './api';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ConnectorsService {

  constructor() { }

  public getConnector(key: string): Promise<Connector | null> {
    return fetch(environment.baseUrl + `/api/connectors/${key.toLowerCase()}`)
      .then(res => {
        if (res.status == 404) {
          return null;
        } else if (!res.ok) {
          throw new Error("Not 2xx response");
        } else {
          return res.json().then(j => j as Connector);
        }
      })
  }

  public getConnectorByQRCode(content: string): Promise<Connector | null> {
    return fetch(environment.baseUrl + '/api/connectors/qr-code?' + new URLSearchParams({
      content: content
    }))
      .then(res => {
        if (res.status == 404) {
          return null;
        } else if (!res.ok) {
          throw new Error("Not 2xx response");
        } else {
          return res.json().then(j => j as Connector);
        }
      })
  }

  public searchConnector(prefixKey: string, lat?: number, lng?: number, limit?: number): Promise<Connector[]> {
    const params: URLSearchParams = new URLSearchParams();
    params.append('prefix', prefixKey);
    if (!!lat) {
      params.append('lat', lat.toString());
    }
    if (!!lng) {
      params.append('lng', lng.toString());
    }
    if (!!limit) {
      params.append('limit', limit.toString());
    }
    return fetch(environment.baseUrl + '/api/connectors?' + params)
      .then(res => res.json())
      .then(res => {
        return res as Connector[]
      })
  }
}
