import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Ereceipt, Location, PaymentTerminalChargingSession } from "../../services/api";

import { ActivatedRoute } from '@angular/router';
import { EreceiptService } from 'src/app/services/ereceipt.service';
import { LocationsService } from 'src/app/services/locations.service';
import { PdfService } from 'src/app/services/pdf.service';
import { PaymentTerminalChargingSessionService } from 'src/app/services/payment-terminal-charging-session.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-ticket-find',
  templateUrl: './ticket-find.component.html',
  styleUrls: ['./ticket-find.component.scss']
})
export class TicketFindComponent implements OnInit {

  /**
   * The Location (charging station) linked to the connector
   */
  public location?: Location;

  public connectorKey?: string;

  /**
   * The current date
   */
  public date: Date = new Date();


  public paymentTerminalChargingSessions: PaymentTerminalChargingSession[] = [];

  /**
   * The error message to display, if any
   */
  public errorMessage?: string;

  constructor(private route: ActivatedRoute, public locationService: LocationsService, public paymentTerminalChargingSessionService: PaymentTerminalChargingSessionService) { }

  ngOnInit(): void {
    // Retrieve location key from the UrL
    this.route.queryParams
      .subscribe(params => {  
        this.connectorKey = params['connectorKey']
        this.locationService.getLocationByConnectorKey(params['connectorKey']).then(location => {
          if (location == null) {
            this.displayErrorMessage($localize`We are sorry, we cannot find any electronic receipt linked to this charging point. Please contact our customer support.`);
          } else {
            this.location = location
          }
        })
        .catch(error => {
          console.log(error);
          this.displayErrorMessage($localize`We are sorry, we cannot find any electronic receipt linked to this charging point. Please contact our customer support.`);
        });
      })
  }

  public onFindButtonClick(): void {
    window.grecaptcha.ready(() => {
      window.grecaptcha.execute(environment.googleRecaptchaKey, { action: 'submit' }).then((token: string) => {
        this.paymentTerminalChargingSessionService.find(this.connectorKey!, token).then((value: PaymentTerminalChargingSession[]) => {
          if (value.length == 0) {
            this.displayErrorMessage($localize`We are sorry, we cannot find any electronic receipt linked to this charging point. Please contact our customer support.`);
          }
          this.paymentTerminalChargingSessions = value;
        })
      });
    });
  }
 
 
  private displayErrorMessage(message: string) {
    this.errorMessage = message;
  }

  public resetErrorMessage(): void {
    this.errorMessage = undefined;
  }

  /**
   * When multiple e-receipt are available, this method is called when one of the receipts is clicked.
   *
   * @param ereceipt
   */
  public onClickTicket(paymentTerminalChargingSession: PaymentTerminalChargingSession): void {    
    // Fetch the image from the service
    this.paymentTerminalChargingSessionService.getTicketPdf(paymentTerminalChargingSession.key).then(arrayBuffer => {
      // Convert ArrayBuffer to Blob
      const blob = new Blob([arrayBuffer], { type: 'application/pdf' });

      // Create a URL for the Blob
      const url = window.URL.createObjectURL(blob);

      // Create an anchor element and trigger the download
      const a = document.createElement('a');
      a.href = url;
      a.download = 'dream-energy-' + paymentTerminalChargingSession.key + '.pdf';
      document.body.appendChild(a);
      a.click();

      // Clean up
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
    }).catch(err => {
      console.error('Error downloading the PDF', err);
    });
  }

  public fromWhToKwhFloor(wh: number) {
    return Math.floor(wh / 1000);
  }

}
