import { Component } from '@angular/core';

@Component({
  selector: 'app-scan-button',
  templateUrl: './scan-button.component.html',
  styleUrls: ['./scan-button.component.scss']
})
export class ScanButtonComponent {

}
