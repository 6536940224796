import { RouterModule, Routes } from '@angular/router';

import { ChargePrepareComponent } from './charge/charge-prepare/charge-prepare.component';
import { ChargeSelectConnectorComponent } from './charge/charge-select-connector/charge-select-connector.component';
import { EReceiptFindComponent } from './e-receipt/e-receipt-find/e-receipt-find.component';
import { MainMapComponent } from './main-map/main-map.component';
import { NgModule } from '@angular/core';
import { TicketFindComponent } from './ticket/ticket-find/ticket-find.component';

const routes: Routes = [
  { path: '', component: MainMapComponent },
  { path: 'charge', component: ChargeSelectConnectorComponent },
  { path: 'charge/:connectorId', component: ChargePrepareComponent },
  { path: 'e-receipt', component: EReceiptFindComponent },
  { path: 'ticket', component: TicketFindComponent }
];


@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
