import { environment } from 'src/environments/environment';
import { Ereceipt, PaymentTerminalChargingSession } from './api';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class PaymentTerminalChargingSessionService {

  constructor() { }

  public find(connectorKey: string, captcha: string): Promise<PaymentTerminalChargingSession[]> {
    const params: URLSearchParams = new URLSearchParams();
    params.append('connectorKey', connectorKey);
    params.append('captcha', captcha);
    return fetch(environment.baseUrl + '/api/payment-terminal-charging-sessions?' + params, {
      headers: {
        Accept: 'application/json'
      }
    })
      .then(res => res.json())
      .then(res => {
        return res as PaymentTerminalChargingSession[]
      })

  }

  public getTicketPdf(paymentTerminalChargingSessionKey: string): Promise<ArrayBuffer> {    
    return fetch(environment.baseUrl + '/api/payment-terminal-charging-sessions/' + paymentTerminalChargingSessionKey + '/ticket')
      .then(res => res.arrayBuffer());
      
  }
}



